import { useStore } from "vuex";
import type { Price } from "~/components/ecommerce/ECommerceProductAccessories/types";
import { formatPrice } from "~/helpers/formatting/price";

export default function useGetPrices(shoppingCart?: any) {
	const { totalEstimate } = useTradeIn();
	const store = useStore();

	const currentTerminal = computed(
		() =>
			shoppingCart?.find((terminal) => !terminal?.metadata?.terminalConfigured && terminal?.type === "hardware") ||
			store.getters["checkout/currentTerminal"],
	);
	const isVatExempt = computed(() => currentTerminal.value?.metadata?.isVatExempt);
	const eligibleMemberPrice = computed(() => store.getters["shoppingCart/eligibleMemberPrice"]);
	const validSubscription = computed(() => currentTerminal?.value?.subItems?.find((item) => item.type === "msisdn"));

	const amountUpFront = computed<number>(() => currentTerminal.value?.metadata?.downPaymentPlan?.amountUpFront);

	const prices: ComputedRef<Price> = computed(() =>
		isVatExempt.value
			? currentTerminal.value?.metadata?.prices?.withoutVat
			: currentTerminal.value?.metadata?.prices?.withVat,
	);

	const fullPricePrices = computed(() => {
		const price =
			eligibleMemberPrice?.value?.productId && validSubscription.value
				? Math.round(
						eligibleMemberPrice?.value?.memberPrice ??
							prices?.value?.fullPrice?.salePrice ??
							prices.value?.fullPrice.price,
					)
				: (prices?.value?.fullPrice?.salePrice ?? prices.value?.fullPrice.price);

		const fullPrice = prices?.value?.fullPrice?.price;

		return {
			price,
			formattedPrice: formatPrice.oneTime(price, { ceil: true }),
			fullPrice,
			formattedFullPrice: price !== fullPrice ? formatPrice.oneTime(fullPrice, { ceil: true }) : undefined,
		};
	});

	const deductedPrice = computed(() => {
		const { price, fullPrice, formattedFullPrice } = fullPricePrices.value;

		let newPrice = price;
		if (amountUpFront.value) {
			newPrice = newPrice - amountUpFront.value;
		}
		if (totalEstimate.value.total) {
			newPrice = newPrice - totalEstimate.value.total;
		}

		return {
			price: newPrice,
			formattedPrice: formatPrice.oneTime(newPrice, { ceil: true }),
			fullPrice: fullPrice,
			formattedFullPrice: formattedFullPrice ?? formatPrice.oneTime(fullPrice, { ceil: true }),
		};
	});

	return {
		prices,
		fullPricePrices,
		deductedPrice,
		amountUpFront,
	};
}
